<template>
  <div v-if="isLoading" class="min-h-90vh">
    <b-loading :is-full-page="true" :active="true" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
  <div v-else class="px-6 min-h-80vh">
    <p class="averta-black label-18 mb-5">Rincian Harga Penawaran</p>

    <div class="flex-center c-w-100">
      <div class="card pdf-table-body size-pdf-table-body mb-3 p-5">
        <div
          v-if="isCompany || dataOffering.prices.discount || dataOffering.prices.subtotal"
          class="is-flex is-justify-content-space-between mb-3"
        >
          <p class="mr-5 averta-bold label-14">{{ isCompany ? 'Harga Jual' : 'Total' }}</p>
          <div class="price-wrapper is-flex is-justify-content-space-between averta-bold">
            <p>Rp</p>
            <p>{{ formatThousand(dataOffering.prices.subtotal) }}</p>
          </div>
        </div>

        <div v-if="!openDiscount" class="discount-field flex-center-vertical averta-bold mb-3">
          <p class="label-12">Tambah Field:</p>
          <div
            class="bg-pink-4 py-1 px-2 flex-center border-radius-22 ml-2 is-clickable"
            @click="handleAddDiscount"
          >
            <p class="color-red label-12">+ Discount</p>
          </div>
        </div>

        <div v-if="dataOffering.prices.discount || openDiscount">
          <div class="mb-3">
            <div class="flex-center-vertical is-justify-content-space-between">
              <div>
                <p class="mr-5 averta-black label-14">Discount</p>
                <p class="label-12 is-clickable color-red" @click="handleRemoveDiscount">X Hapus Field</p>
              </div>
              <div class="price-wrapper flex-center-vertical is-justify-content-space-between averta-bold">
                <p class="mr-3">Rp</p>
                <div class="input-discount-offering">
                  <InputPrice
                    :parentClassName="isEmptyDiscount ? 'error-input-validation' : ''"
                    :className="`averta-bold ${isEmptyDiscount ? 'bg-pink-5' : ''}`"
                    :hideFormat="true"
                    :value="dataOffering.prices.discount"
                    :maxValue="dataOffering.prices.subtotal"
                    @change="$event => handleChangeDiscount($event)"
                  />
                </div>
              </div>
            </div>
            <div class="is-flex is-justify-content-flex-end input-discount-offering" v-if="isEmptyDiscount">
              <ErrorComponent title="Discount harus lebih dari 0" />
            </div>
          </div>

          <div class="is-flex is-justify-content-space-between mb-3">
            <p class="mr-5 averta-black label-14">{{ titleAfterDiscount }}</p>
            <div class="price-wrapper is-flex is-justify-content-space-between averta-bold">
              <p>Rp</p>
              <p>{{ formatThousand(dataOffering.prices.total_after_discount) }}</p>
            </div>
          </div>
        </div>

        <div v-if="isCompany" class="is-flex is-justify-content-space-between mb-3">
          <p class="mr-5 averta-bold label-14">
            <!-- PPN {{ dataOffering.prices.ppn_percent }}% -->
            PPN
          </p>
          <div class="price-wrapper is-flex is-justify-content-space-between averta-bold">
            <p>Rp</p>
            <p>{{ formatThousand(dataOffering.prices.ppn) }}</p>
          </div>
        </div>

        <div
          class="is-flex is-justify-content-space-between mb-3"
          v-if="isCompany && dataOffering.prices.pph && dataOffering.prices.pph_percent"
        >
          <p class="mr-5 averta-bold label-14">
            PPh Final 4 Ayat 2 ({{ dataOffering.prices.pph_percent }}%)
          </p>
          <div class="price-wrapper is-flex is-justify-content-space-between averta-bold">
            <p>Rp</p>
            <p>({{ formatThousand(dataOffering.prices.pph) }})</p>
          </div>
        </div>

        <div
          v-if="isCompany || this.dataOffering.prices.discount"
          class="divider-grand-total"
        ></div>

        <div class="is-flex is-justify-content-space-between wrapper-grand-total mb-3 pl-3 py-2">
          <p class="mr-5 averta-bold label-18">Grand Total</p>
          <div class="price-wrapper is-flex is-justify-content-space-between">
            <p class="averta-black text-grand-total label-18">Rp</p>
            <p class="averta-black text-grand-total label-18">
              {{ formatThousand(dataOffering.prices.total_after_rounding) }}
            </p>
          </div>
        </div>

        <div class="is-flex is-justify-content-flex-end">
          <p>
            (<span class="averta-bold">Terbilang: </span>
            <span class="averta-regular-italic">{{
              formatSpellOut(dataOffering.prices && dataOffering.prices.total_after_rounding)
            }}</span
            >)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import { formatCurrency, Terbilang, alertErrorMessage } from '@/helpers'
import alertImage from '@/assets/img/delete-area-component.png'

export default {
  name: 'DetailPriceOffering',
  components: {
    SpinnerIcon: () => import('@/components/icons/SpinnerIcon.vue'),
    InputPrice: () => import('@/components/input/InputPrice.vue'),
    ErrorComponent: () => import('@/components/ErrorComponent.vue')
  },
  data () {
    return {
      isLoading: false,
      dataOffering: {},
      openDiscount: false,
      discountValue: null,
      titleAfterDiscount: 'Harga Sesudah Discount'
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isCompany () {
      return (
        this.dataOffering.client_type_code === 'company' ||
        this.dataOffering.client_type_code === null ||
        !this.dataOffering.client_type_code
      )
    },
    isEmptyDiscount () {
      return this.openDiscount && (!this.discountValue || this.discountValue === '0')
    }
  },
  methods: {
    formatThousand (value) {
      return !value ? 0 : formatCurrency(value, true) === '-' ? 0 : formatCurrency(value, true)
    },
    formatSpellOut (value) {
      return Terbilang(value || 0)
    },
    getDetailPriceOffering () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId
      }

      this.$store
        .dispatch('projectV2/getPreviewReject', payload)
        .then(response => {
          const res = response.data.data

          const obj = {
            client_type_code: res.client_type ? res.client_type.code : null,
            prices: {
              ...res.preview_price,
              ppn_percent: res.preview_price.ppn_percent.replace('%', ''),
              pph_percent: res.preview_price.pph_percent.replace('%', ''),
              total: res.preview_price.total_after_rounding
            }
          }
          this.dataOffering = obj
          this.discountValue = this.dataOffering.prices.discount || 0
          if (this.discountValue) {
            this.openDiscount = true
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getTotalRejected () {
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId
      }

      this.$store
        .dispatch('project/getTotalRejected', payload)
        .then(response => {
          const totalReject = response.data.data.total_reject
          this.titleAfterDiscount = totalReject > 1 ? 'Harga Sesudah Nego' : 'Harga Sesudah Discount'
        })
        .catch(error => {
          alertErrorMessage(error)
        })
    },
    handleAddDiscount () {
      this.openDiscount = true
      this.dataOffering.prices.discount = 0
    },
    handleChangeDiscount (value) {
      const temp = value.toString().replace(/\D/g, '')
      this.dataOffering.prices.discount = this.formatThousand(temp)
      this.discountValue = temp

      const dpp = JSON.parse(JSON.stringify(this.dataOffering.prices.subtotal || 0))
      const discount = JSON.parse(JSON.stringify(value))
      const ppn = Math.round((dpp - discount) * (this.dataOffering.prices.ppn_percent / 100))
      const pph = Math.round((dpp - discount) * (this.dataOffering.prices.pph_percent / 100))
      const result = Math.round(((dpp - discount) + ppn) - pph)

      this.dataOffering.prices.ppn = ppn
      this.dataOffering.prices.pph = pph
      this.dataOffering.prices.total_after_discount = dpp - discount
      this.dataOffering.prices.total_after_rounding = result
    },
    handleRemoveDiscount () {
      if (!this.discountValue || this.discountValue === '0') {
        this.handleChangeDiscount(0)
        setTimeout(() => {
          this.openDiscount = false
          this.discountValue = null
          this.dataOffering.prices.discount = 0
        }, 100)
      } else {
        this.$swal({
          imageUrl: alertImage,
          imageAlt: 'confirm remove discount',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Hapuse Field "Discount"</p>',
          html: '<p class="averta-regular has-text-centered is-inline-block" style="width: 330px;">Informasi Discount yang telah dimasukan akan otomatis dihapus </p>',
          showCancelButton: true,
          confirmButtonText: 'Hapus Field',
          cancelButtonText: 'Cek Kembali',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          },
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.handleChangeDiscount(0)
            setTimeout(() => {
              this.openDiscount = false
              this.discountValue = null
              this.dataOffering.prices.discount = 0
            }, 100)
          }
        })
      }
    }
  },
  created () {
    this.getDetailPriceOffering()
    this.getTotalRejected()
  },
  watch: {
    isEmptyDiscount (val) {
      this.$emit('changeDiscount', {
        empty: val,
        discount: this.discountValue
      })
    },
    discountValue (val) {
      this.$emit('changeDiscount', {
        empty: this.isEmptyDiscount,
        discount: val
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrapper-grand-total {
  background: linear-gradient(90deg, #fffff7 0%, #fff5ea 100%);
  border-radius: 8px;
  font-size: 17px;
  margin: 0px -10px;
  padding: 0px 10px;
}

.text-grand-total {
  color: #d9272d;
}

.price-wrapper {
  width: 200px;
}

.size-pdf-table-body {
  max-width: 45em;
  width: 45em;
}

.divider-grand-total {
  background: #e1e1e1;
  height: 1px;
  margin: 20px 0px;
}

.discount-field {
  border-radius: 12px;
  padding: 8px 12px;
  background: #fafafa;
}

.input-discount-offering {
  margin-right: -8px;
}

.error-input-validation {
  border-color: #E10009 !important;
}
</style>
